import React from 'react';
import { hydrate, render } from 'react-dom';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from 'react-router-dom'; 
import { ImpressumPage } from './Legal';


const root = document.getElementById('root');
const rndr = root && root.childElementCount > 0 ? hydrate : render;

rndr(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} ></Route>
      <Route path='/impressum' element={<ImpressumPage />} ></Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
