const themes = {
    primary: {
        main: '#39A845',
        sub: '#2E8637',
    },
    secondary: {
        main: '#C1DB3C',
        sub: '#A3BC23',
    },
    background: {
        main: '#717171',
        sub: 'white',
        contrast : '#f9f9f9',
    }
}

export {themes}