import styled from "styled-components";
import React, { ReactElement, useEffect, useState } from "react";
import { Grid, GridElement } from "./grid";
import { themes } from "./theme";
import { Card } from "./card";
import { Flexbox, FlexboxElement } from "./flex";

const StyledBody = styled.div`
  background-size: cover;
  color: ${themes.primary.main};
  background-color: ${themes.background.sub};
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
`;

const StyledTextHeader = styled.div<{animate: boolean}>`
  text-align: center;
  font-size: 3.6rem;
  font-weight: 200;
  color: ${themes.primary.main};
  margin-top: -2.6rem;
  padding-bottom: 2.6rem;
`;

const StyledTextFooter = styled.div<{animate: boolean}>`
  text-align: center;
  font-size: 3.6rem;
  font-weight: 200;
  margin-bottom:-2rem;
  padding-top: 2.6rem;
  color: ${themes.primary.main};
`;

const StyledTextLetters = styled.span<{animate: boolean}>`
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.6s;
    opacity: 0;
    &:nth-child(7){
        opacity:1;
    };
    ${(props) =>
        props.animate && `
        opacity:1;
    `}
`;

const StyledTextBody = styled.div`
  text-align: center;
  font-size: 1.6rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;

const StyledTextBodyFooter = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;

const StyledTextBodyHeader = styled.div`
  text-align: center;
  font-size: 3rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;

const Body = () : ReactElement => {
    const [toggle, setToggle] = useState(false)

    const handleScroll = () => {
        const position = window.scrollY;
        if(position > 95) {
            setToggle(true)
        }
        if(position <= 50){
            setToggle(false)
        }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
            window.removeEventListener('scroll', handleScroll);
		  };
	}, []);

    return(
    <StyledBody>
        <Grid parts={1} dimension={"1"}>
            <GridElement position=''>
                <StyledTextHeader animate={toggle} >
                    <StyledTextLetters animate={toggle} >V</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >E</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >R</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >T</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >I</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >C</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >A</StyledTextLetters>
                    <StyledTextLetters animate={toggle} >L</StyledTextLetters>
                </StyledTextHeader>   
            </GridElement>
            <GridElement position=''>
                <StyledTextBodyHeader>
                    SAVE WATER and  explore soilless agriculture
                </StyledTextBodyHeader>
                <StyledTextBody>
                 A Vertical Tower Farm is a vertical indoor farming system that uses a single or series of towers to grow plants in a controlled environment. The towers consist of multiple levels equipped with hydroponic or aeroponic systems.

                The design of the Vertical Tower Farm is space-saving and enables growing a large number of plants and herbs in a small area. The towers are equipped with automated irrigation and nutrient supply to ensure optimal plant growth.

                Vertical Tower Farms require 80 to 95% less water and up to 75% less fertilizer.

                Vertical Tower Farms are particularly suitable for urban areas where space is limited. They can be housed in apartments or gardens and can operate throughout the year, regardless of weather conditions or seasonal availability.

                Overall, the Vertical Tower Farm provides a sustainable and innovative solution to the challenges of modern food production by minimizing the use of water, pesticides, and fertilizers, as well as reducing transport costs and carbon emissions.
                </StyledTextBody>
                <StyledTextBodyFooter>
                    An aeroponic system is a type of indoor farming system where plants are suspended in the air and receive their nutrients from a fine mist. The system is very efficient in water consumption and can achieve higher crop yields than other cultivation methods.
                </StyledTextBodyFooter>
                <Flexbox align='center' direction='row' wrap='wrap'>
                    <FlexboxElement align='flex-start' order={0} grow={0}>
                        <Card childFront={<><h1>AEROPONICS</h1></>} childBack={<>An aeroponic system is a type of indoor farming system where plants are suspended in the air and receive their nutrients in a fine mist. The system is very efficient in water consumption and can achieve higher crop yields than other cultivation methods.</>}></Card>
                    </FlexboxElement>
                    <FlexboxElement align='flex-start' order={0} grow={0}>
                        <Card childFront={<><h1>NUTRIENT FILM TECHNIQUE</h1></>} childBack={<>The Nutrient Film Technique is a hydroponic cultivation method where a flat channel is filled with a thin film of nutrient solution that surrounds the roots of the plants. The system uses the principle of gravity to continuously flow the nutrient solution through the channel, providing the plants with nutrients.</>}></Card>
                    </FlexboxElement>
                    <FlexboxElement align='flex-start' order={0} grow={0}>
                        <Card childFront={<><h1>DRIP SYSTEM</h1></>} childBack={<>A drip system is a hydroponic cultivation method where plants are supplied with nutrients through tubes or pipes connected to a pump. The nutrient solution is directed in small drops directly onto the roots of the plants, enabling precise control over the amount of nutrients each plant receives.</>}></Card>
                    </FlexboxElement>
                    <FlexboxElement align='flex-start' order={0} grow={0}>
                        <Card childFront={<><h1>DEEP WATER CULTURE</h1></>} childBack={<>Deep Water Culture is a hydroponic cultivation method where plants float in a tank of nutrient solution. The roots of the plants hang freely in the water and receive oxygen through air stones submerged in the tank.</>}></Card>
                    </FlexboxElement>                    
                    <FlexboxElement align='flex-start' order={0} grow={0}>
                        <Card childFront={<><h1>EBB AND FLOW</h1></>} childBack={<>Ebb and Flow/ Flood and Drain is a hydroponic cultivation method where plants are placed in a tank that is periodically filled and drained with nutrient solution. The system uses a pump and timer to pump water into the tank and then drain it, intermittently supplying the roots of the plants with nutrients.</>}></Card>
                    </FlexboxElement>
                </Flexbox>
            </GridElement>
            <GridElement position="">
                <StyledTextFooter animate={toggle}>
                    FARMING
                </StyledTextFooter>  
            </GridElement>
        </Grid>
  </StyledBody>
  )
}

export {Body}