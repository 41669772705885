import React, { ReactElement, useState, useEffect, ReactNode } from "react";
import styled from "styled-components";
import { Button } from "./button";
import { themes } from "./theme";

const StyledBackgroundImageWrapper = styled.div`
  overflow-x:hidden;
  overflow-y:auto;
`;

const StyledBackground = styled.div`
  overflow-x:hidden;
  overflow-y:auto;
`;

const StyledBackgroundImage = styled.div<{src : string, size: string}>`
  background-image: url(${(props) => props.src});
  min-height: ${(props) => props.size};
  width:100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  z-index: -1;
`;

interface BackgroundProps {
    src: string;
    size: string;
    placeholder?: string;
    children?: ReactNode;
}


const Background = ({children, src, size}: BackgroundProps): ReactElement => {
    const [toggle, setToggle] = useState(false)

    const handleScroll = () => {
        const position = window.scrollY;
        if(position > 70) {
            setToggle(true)
        }
        if(position <= 50){
            setToggle(false)
        }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
            window.removeEventListener('scroll', handleScroll);
		  };
	}, []);

    return <StyledBackground>
              <StyledBackgroundImageWrapper>
              {children}
              <StyledBackgroundImage size={size} src={process.env.PUBLIC_URL + src}/>
              </StyledBackgroundImageWrapper>
          </StyledBackground>
}

export { Background }
