import styled from "styled-components";
import React, { ReactElement, useEffect, useState } from "react";
import { Grid, GridElement } from "./grid";
import { themes } from "./theme";
import { Card } from "./card";
import { Flexbox, FlexboxElement } from "./flex";

const StyledSoftware = styled.div`
  background-size: cover;
  color: ${themes.primary.main};
  background-color: ${themes.background.sub};
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 8rem;

`;

const StyledTextSoftwareBody = styled.div`
  font-size: 1.6rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;
const StyledTextSoftware = styled.div`
  max-width: 700px;
`;

const StyledTextSoftwareFooter = styled.div`
  font-size: 0.8rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;

const StyledTextSoftwareHeader = styled.div`
  font-size: 3rem;
  font-weight: 200;
  padding: 2rem 1rem 2rem 1rem;
  color: ${themes.primary.main};
  word-break: normal;
`;


const StyledTextSoftwarePicture = styled.div`
  background: url("./sprout-sneakpeak.png");
  background-size:cover;
  width: 500px;
  height: 300px;
  border: 1px solid black;
`;

const StyledTextSoftwareBorder = styled.div`
  border: 4px solid black;
  padding: 1rem;
  border: 4px solid black;
  border-radius: 8px;
  background: white;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

`;


const Software = () : ReactElement => {
    const [toggle, setToggle] = useState(false)

    const handleScroll = () => {
        const position = window.scrollY;
        if(position > 95) {
            setToggle(true)
        }
        if(position <= 50){
            setToggle(false)
        }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
            window.removeEventListener('scroll', handleScroll);
		  };
	}, []);

    return(
    <StyledSoftware>
        <Flexbox align='center' direction='row' wrap='wrap'>
            <FlexboxElement align='flex-start' order={0} grow={0}>
                  <StyledTextSoftware>
                  <StyledTextSoftwareHeader>
                      Monitor your future farm and optimize your harvest
                  </StyledTextSoftwareHeader>
                  <StyledTextSoftwareBody>
                      regardless of your space
                  </StyledTextSoftwareBody>
                  <StyledTextSoftwareFooter>
                    *coming soon
                  </StyledTextSoftwareFooter>
                  </StyledTextSoftware>
            </FlexboxElement>
            <FlexboxElement align="flex-end" order={1} grow={0}>
              <StyledTextSoftwareBorder>
                <StyledTextSoftwarePicture/>
              </StyledTextSoftwareBorder>
            </FlexboxElement>
        </Flexbox>
  </StyledSoftware>
  )
}

export {Software}