import React, {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';

interface GridProps {
    dimension: string;
    align?: string;

    parts: number;
    children: ReactNode;
}

const StyledGrid = styled.div<{align : string, dimension : string, parts : number}>`
    display:grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(${(props) => props.parts}, 1fr);
    grid-template-areas: "${(props) => props.dimension}";
    justify-items: ${(props) => props.align};
    background: transparent;
`;

const Grid = ({align, dimension, parts, children } : GridProps) : ReactElement => {
    return (<StyledGrid align={align ? align : "start"} parts={parts} dimension={dimension}>{children}</StyledGrid>)
}

const StyledGridElement = styled.div<{align: string, position : string}>`
    justify-self: ${(props) => props.align};
    grid-area: ${(props) => props.position};
`;


interface GridElementProps {
    position: string;
    align?: string;
    children: ReactNode;
}

const GridElement = ({align, position,children } : GridElementProps) : ReactElement => {
    return (<StyledGridElement align={align ? align : "start"} position={position}>{children}</StyledGridElement>)
}

export {Grid, GridElement}