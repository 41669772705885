import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {themes} from './theme'

const StyledButton = styled.a<{ main: boolean }>`
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-weight: 600;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: white;
    background: none;
    cursor: pointer;
    padding: 1rem 2rem;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: ${(props) => props.main ? themes.primary.main : themes.secondary.main};
    &:hover {
        background: ${(props) => props.main ? themes.primary.sub : themes.secondary.sub};
    }
`

interface ButtonProps {
    onClick? : () => void
    main: boolean;
    value: string;
    to: string;
}

const Button = ({ onClick, main, value, to }:ButtonProps) : ReactElement => {
    return <StyledButton href={to} main={main} onClick={onClick}>{value}</StyledButton>
}

export {Button}