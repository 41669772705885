import  React, { ReactElement } from 'react';
import styled from 'styled-components';
import {themes} from './ui/theme';
import { Header } from './ui/header';
import { Background } from './ui/background';
import { Body } from './ui/body';
import { CloudAnimation } from './ui/wave';
import { PotAnimation } from './ui/pot';
import { Software } from './ui/software';
import { Products } from './ui/products';
import { Button } from './ui/button';


const StyledApp = styled.div`
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-weight: 600;
  overflow: hidden;
`;

const StyledFooter = styled.div`
  background: ${themes.background.contrast};
  text-align: center;
  height: 15vh;
  padding-top: 10vh;
`;


const StyledBackgroundSlogan = styled.div<{animate: boolean}>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 30vh;
  transition: all 0.3s ease;
  font-size: 3rem;
  background: ${themes.primary.main};
  color: ${themes.background.contrast};
  padding: 0.5rem;
  opacity: 1 ;
  ${(props) =>
    props.animate && `
        opacity:0;
    `}
`;

const StyledBackgroundSubSlogan = styled.div<{animate: boolean}>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50vh;
  width: 70%;
  transition: all 0.3s ease;
  font-size: 2rem;
  font-weight: 400;
  background: ${themes.primary.sub};
  color: ${themes.background.sub};
  padding: 1rem;
  opacity: 1 ;
  ${(props) =>
    props.animate && `
        opacity:0;
    `}
`;

const App = (): ReactElement => {
  return (
    <StyledApp>
      <Header/>
      <Background
        size='100vh'
        src='"./Vertical-farm.jpg"'
        children={
          <>
            <StyledBackgroundSlogan animate={false}>
            grow the food of the future
            </StyledBackgroundSlogan>
            <StyledBackgroundSubSlogan animate={false}>
            SPROUT provides you with everything you need to grow you own food simply at home.
            </StyledBackgroundSubSlogan>
          </>
        }
      />
      <Body/>
      <Background size='50vh' src='"./Hydroponic-tower.jpg"'/>
      <Software/>
      <Products/>
      <Background size='50vh' src='"./seedlings.jpg"'/>
      <PotAnimation/>
      <CloudAnimation top={false} transform=''></CloudAnimation>
      <StyledFooter>
          <Button main value='imprint' to='/impressum'/>
      </StyledFooter>
    </StyledApp>
  );
}

export {App};
