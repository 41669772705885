import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, GridElement } from './grid';
import { IconButton } from './icon';
import { themes } from './theme';
import { Button } from './button';


const StyledHeader = styled.div<{animate : boolean}>`
    font-family: 'Montserrat', sans-serif;
    background: transparent;
    backdrop-filter: blur(25px) brightness(1.5);

    width: 100%;
    transition: all 0.3s ease;
    z-index: 9999;
    position: fixed;
    height: 50px;
    top: 0;
    padding: 1rem 0;

  ${(props) =>
    props.animate && `
        background: ${themes.background.contrast};
  `}
`;

const StyledHeaderLogo =styled.a<{animate:boolean}>`
    color: ${themes.primary.main};
    font-size: 2rem;
    transition: all 0.3s ease;
    padding: 1rem;
    &:before{
        content: "SPROUT"
    }
    ${(props) =>
        props.animate && `
        font-size: 1.2rem;
            height: 50px;
            border: 1px white solid;
            padding: 0.5rem;

            &:before{
                content: url('./icon32.png')
            }
      `}
`;

interface HeaderProps {
    children?: ReactElement;
}

const Header = ({children} : HeaderProps) : ReactElement => {

    const [toggle, setToggle] = useState(false)

    const handleScroll = () => {
        const position = window.scrollY;
        if(position > 70) {
            setToggle(true)
        } else {
            setToggle(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

		return () => {
            window.removeEventListener('scroll', handleScroll);
		};
	}, []);

    return (
        <StyledHeader animate={toggle}>
            <Grid dimension='a b' parts={2}>
                <GridElement position='a'>
                    <StyledHeaderLogo animate={toggle} ></StyledHeaderLogo>
                </GridElement>
                <GridElement position='b' align='end'>
                    <Button to='http://195.128.103.147:3000/' main value={'beta'}></Button>
                </GridElement>
            </Grid>
        </StyledHeader>
    )
}

export {Header}