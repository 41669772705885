import styled, { keyframes } from "styled-components";
import { themes } from './theme';
import React, {ReactElement, ReactNode} from 'react';
import { Grid, GridElement } from "./grid";

const StyledGetInTouch = styled.input`
    position: relative;
    transition: all 0.3s ease;
    font-size: 2rem;
    border: none;
    border-bottom: 1px solid green;
    margin-top: 5rem;
    vertical-align: bottom;
    padding: 0.5rem;
    &:focus{
        outline: none;
    }
`;

const StyledGetInTouchButton = styled.button`
    font-family: 'Montserrat',sans-serif;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-weight: 600;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: white;
    background: none;
    cursor: pointer;
    padding: 1rem 2rem;
    margin-left: 1rem;
    display: inline-block;
    text-transform: uppercase;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: ${themes.primary.main};
`;

const StyledGetInTouchSlogan = styled.div`
  position: relative;
  font-size: 2.5rem;
  font-weight: 300;
  text-align:center;
  margin-top: 4rem;

  color: ${themes.primary.main};
`;

const PotAnimation = () : ReactElement => {
  return (
    <>
    <StyledGetInTouchSlogan>
        Stay updated for upcoming news and projects
    </StyledGetInTouchSlogan>
    <Grid align="center" dimension="a" parts={1}>
        <GridElement position="a" align="center">
            <form action="https://formspree.io/f/mwkjwgzr" method="post">
                <StyledGetInTouch placeholder="E-Mail" type="email" id="email" name="email" aria-label="email" />
                <StyledGetInTouchButton type="submit">submit</StyledGetInTouchButton>
            </form>
        </GridElement>
    </Grid>
    </>
    );
  }
  
  export { PotAnimation }